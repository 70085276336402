#title {
    font-weight: bold;
    font-size: calc(4vw + 1.5em);
}

#subtitle {
    font-weight: 200;
    font-size: calc(2vw + 1em);
}

.container {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    margin: 0 20vw 0 20vw;
    align-items: center;
}


#profileImage {
    width: 30%;
}


@media screen and (max-width:540px) {
    #profileImage {
        margin-left: 15%;
    }

    #title {
        font-size: calc(2vw + 1.5em);
    }
    
    #subtitle {
        font-size: calc(0.5vw + 1em);
    }
}


@media screen and (max-width:420px) {
    #title {
        font-size: calc(1vw + 1.5em);
    }
    
    #subtitle {
        font-size: calc(0.25vw + 1em);
    }
}


@media screen and (max-width:370px) {
    #title {
        font-size: calc(0.5vw + 1.15em);
    }
    
    #subtitle {
        font-size: calc(0.125vw + 0.55em);
    }
}


