.container {
    width: 15%;
    height: 10%;   
}

.container img {
    border: 1px solid grey;
    width: 100%;
}

.info {
    padding-top: 0;
    border: 1px solid grey;
}