@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css");

* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
}

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg);
  color: var(--fg);

  &.light {
    --bg: #fff;
    --fg: #000;
  }

  &.dark {
    --bg: rgb(30, 30, 30);
    --fg: white;
  }
}

