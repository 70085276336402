.full {
    width:100vw;
    background-color: white;
    color: black;
    height: 100vh;
}

.projects {
    padding-top: 15vh;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}